import React from 'react';
import { graphql } from 'gatsby';
import Navbar from '../components/navbar/navbar';
import ResetCss from '../components/reset-css';
import SEO from '../components/seo';
import NotFound from '../containers/not-found';
import Layout from '../components/layout4';

const NotFoundPage = (props: any) => {
  return (
    <>
    <Layout>
      <ResetCss />
      <Navbar />
      <SEO title="404: Not Found" />
      <NotFound />
    </Layout>
    </>
  );
};

export default NotFoundPage;

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`;
